import './style.css'
import Swal from 'sweetalert2'
import axios from 'axios'

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())
let token = decodeURIComponent(params['t'])

const rewardBaseUrl = process.env.REWARDS_URL;

const rewardsAxios = axios.create({
    baseURL: rewardBaseUrl,

})

let scrippsvideoplayer = document.getElementById('scrippsvideoplayer');
let bannerImgElement = document.getElementById('banner-img');

let adID = ''
const scrippsVideoPlayerEvents = {
    'AD_LOADED': function (d) {
        //d.data.adData contains info on the ad
        adID = d.data.adData.adId

        rewardsAxios({
            url: "banner",
            params: {
                adId: adID
            },
            method: "GET",
        }).then((response) => {
            console.log(response.data);
            bannerImgElement.src = response.data;
        }).catch(() => {});
    },
    'AD_START': function () {
        //ad video has begun to play
        rewardsAxios({
            url: "reward",
            method: "POST",
            data: {
                token: token,
                adId: adID,
                event: 'ad_start'
            }
        }).then(() => {}).catch(() => {});
    },
    'AD_ERROR': function (d) {
        scrippsvideoplayer.innerHTML = ''

        switch (d.type) {
            case 'adPlayError':
                //an ad was returned but failed during or at the beginning of playback
                Swal.fire({
                    icon: 'warning',
                    title: 'Uh oh!',
                    text: 'An error has occurred.'
                })
                // msgContainer.innerHTML = 'An error has occurred.'
                break;
            case 'adLoadError':
                //no ad was returned from the ad server
                Swal.fire({
                    icon: 'warning',
                    title: 'Sorry!',
                    text: 'No ads are available at this time.'
                })
                break;
        }

        rewardsAxios({
            url: "reward",
            method: "POST",
            data: {
                token: token,
                adId: adID,
                event: 'ad_error',
                message: d.data.message
            }
        }).then(() => {}).catch(() => {});
    },
    'AD_END': function () {
        //ad video has finished playing
        Swal.fire({
            icon: "success",
            title: "Thank You!",
            text: "Please close this window to return to the game.",
        })
        scrippsvideoplayer.innerHTML = ''
        rewardsAxios({
            url: "reward",
            method: "POST",
            data: {
                token: token,
                adId: adID,
                event: 'ad_complete'
            }
        }).then(() => {}).catch(() => {});
    },
    'IMA_NOT_LOADED': function(){
        //google's ima sdk did not load
        scrippsvideoplayer.innerHTML = ''
        Swal.fire({
            icon: 'warning',
            title: 'Uh oh!',
            text: 'You have an extension or ad blocker enabled. Please disable it and try again.'
        })
        rewardsAxios({
            url: "reward",
            method: "POST",
            data: {
                token: token,
                adId: adID,
                event: 'ad_blocker'
            }
        }).then(() => {}).catch(() => {});
    }
}

var ScrippsVideoPlayer = {
        init: function (a) {
            a.selector = a.selector || ".scrippsvideoplayer";
            var b = document.querySelector(a.selector);
            if (b) {
                var c = this.getIframeSrc(a);
                this.initEvents(c, a.onEvent), (b.innerHTML = this.getHtml(c));
            }
        },
        getHtml: function (a) {
            return (
                '<div class="scripps_iframe_embed" style="position:relative;"><div style="display:block;width:100%;height:auto;padding-bottom:56.25%;"></div><iframe style="position:absolute;top:0;left:0;width:100%; height:100%;" border="0" height="100%" frameborder="0" webkitallowfullscreen="" allowfullscreen="" mozallowfullscreen="" scrolling="no" scrolling="no" src="' +
                a +
                '"></iframe></div>'
            );
        },
        getIframeSrc: function (a) {
            var b = "https://imm.ewscloud.com/video/minecraft/player.html?";
            return (
                a.video && (b += "video=" + a.video),
                a.poster && (b += "poster=" + a.poster),
                a.ads &&
                (a.ads.bidders && (b += "&ads.bidders=" + a.ads.bidders),
                a.ads.proxy && (b += "&ads.proxy=" + a.ads.proxy),
                a.ads.iu && (b += "&ads.iu=" + a.ads.iu),
                a.ads.url && (b += "&ads.url=" + a.ads.url),
                a.ads.description_url && (b += "&ads.description_url=" + a.ads.url),
                a.ads.tfcd && (b += "&ads.tfcd=" + a.ads.tfcd),
                a.ads.ad_rule && (b += "&ads.ad_rule=" + a.ads.ad_rule),
                a.ads.output && (b += "&ads.output=" + a.ads.output)),
                    b
            );
        },
        initEvents: function (a, b) {
            window.addEventListener("message", function (c) {
                if (c && c.data)
                    try {
                        var a = JSON.parse(c.data),
                            d = b[a.event];
                        if (d) {
                            if (a.data)
                                try {
                                    a.data = JSON.parse(a.data);
                                } catch (e) {}
                            d(a);
                        }
                    } catch (f) {}
            });
        },
    },
    onEvent = scrippsVideoPlayerEvents || {},
    svpContainer = ".scrippsvideoplayer";

window.onload = function() {
    ScrippsVideoPlayer.init({
        selector: svpContainer,
        video: "blank.mp4",
        ads: { bidders: "none", proxy: 1, iu: "/6088/misfitsgaming/minecraft_reward", tfcd: "1", url: encodeURIComponent("https://www.newsy.com/"), description_url: encodeURIComponent("https://www.newsy.com/"), disableClickthrough: !0 },
        onEvent: onEvent,
    });

    document.getElementById("browser-warnings").classList.add("hidden");
    document.getElementById("rewards-container").classList.remove("hidden");
}
